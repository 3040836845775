<template>
  <v-container fluid tag="section" class="pt-2 settings-grid">
    <div class="--controls">
      <ul class="br-16 py-5 text-ledt dark-grey--text">
        <v-badge
          class="--item mb-2"
          dot
          offset-x="0"
          offset-y="65%"
          :color="
            table_booking_state.is_general_ready ? 'transparent' : 'accent'
          "
          overlap
        >
          <router-link class="mr-2" to="/booking/general">
            General
          </router-link>
        </v-badge>
        <v-badge
          class="--item mb-2"
          dot
          offset-x="0"
          offset-y="65%"
          :color="table_booking_state.is_hours_ready ? 'transparent' : 'accent'"
          overlap
        >
          <router-link class="mr-2" to="/booking/working-hours"
            >Opening Hours</router-link
          >
        </v-badge>
        <v-badge
          class="--item mb-2"
          dot
          offset-x="0"
          offset-y="65%"
          :color="
            table_booking_state.is_tables_ready ? 'transparent' : 'accent'
          "
          overlap
        >
          <router-link class="mr-2" to="/booking/tables"
            >Tables Setup</router-link
          >
        </v-badge>

        <router-link class="mr-2 mb-2" to="/booking/gallery"
          >Gallery</router-link
        >
        <v-badge
          v-if="$vuetify.breakpoint.mdAndUp"
          class="--item mb-2"
          dot
          offset-x="0"
          offset-y="65%"
          :color="
            table_booking_state.is_tables_ready ? 'transparent' : 'accent'
          "
          overlap
        >
          <router-link class="mr-2" to="/booking/widget">Widget</router-link>
        </v-badge>
      </ul>
    </div>
    <div class="--main br-16">
      <v-progress-linear v-if="loading" indeterminate color="primary" />
      <transition name="fade" mode="out-in">
        <router-view
          :execute-update="execute_update"
          @loading="(is_loading) => (loading = is_loading)"
          @update:finished="execute_update = false"
          @form-valid="(is_valid) => (form_valid = is_valid)"
          @change="(changed) => (_is_save_settings_enabled = changed)"
          @show-snackbar="snackbar = true"
        />
      </transition>
    </div>
    <div class="--action text-right">
      <v-btn
        @click="execute_update = true"
        rounded
        color="primary"
        :disabled="!form_valid || !_is_save_settings_enabled || execute_update"
      >
        <v-progress-circular
          indeterminate
          :size="20"
          v-if="execute_update"
        /><span v-else> Update Settings</span></v-btn
      >
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
    >
      <b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "TableBookingSettings",
  data() {
    return {
      form_valid: false,
      is_save_settings_enabled: false,
      execute_update: false,
      snackbar: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("ErrorStore", ["ignore_before_route_leave_guard"]),
    ...mapGetters("TableBookingStore", ["table_booking_state"]),
    _is_save_settings_enabled: {
      get() {
        return this.is_save_settings_enabled;
      },
      set(v) {
        this.is_save_settings_enabled = v;
        this.set_unsaved_settings(v);
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      this._is_save_settings_enabled &&
      !this.ignore_before_route_leave_guard
    ) {
      this.set_error({
        error: {
          message:
            "You have unsaved changes, please save them first before navigating away",
          to: to.path,
        },
      });
      return;
    }
    next();
  },
  methods: {
    ...mapActions("ErrorStore", ["set_error"]),
    ...mapActions("TableBookingStore", ["set_unsaved_settings"]),
  },
};
</script>

<style lang="scss" scoped>
.settings-grid {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    grid-template-columns: 1.5fr 9fr;
    grid-template-rows: 10fr 1fr;
    grid-template-areas:
      "controls main"
      ". action";
    grid-column-gap: 2.5rem;
    max-height: calc(100vh - 75px);
    min-height: calc(100vh - 75px);
  }
  padding: 0 2.5rem;

  .--controls {
    grid-area: controls;
    margin-bottom: 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-bottom: 0;
    }

    ul {
      background-color: white;
      list-style-type: none;
      display: flex;
      overflow-x: auto;
      padding: 0 2rem;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        flex-direction: column;
        align-items: start;
        padding-left: 2rem;
        padding-right: 0;
      }

      a {
        display: inline-block;
        color: var(--v-dark-grey-base);
        text-decoration: none;
        // text-transform: uppercase;
        margin-right: 1.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 3px solid transparent;
        transition: border-color 0.125s ease-in-out;
        white-space: nowrap;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          margin-bottom: 1rem;
          margin-right: 0;
          white-space: unset;
        }

        &:hover {
          border-bottom-color: var(--v-dark-grey-base);
        }

        &:last-of-type {
          margin-bottom: 0;

          @media #{map-get($display-breakpoints, 'md-and-up')} {
            padding-right: 0;
          }
        }
      }

      .router-link-exact-active {
        color: var(--v-primary-base);
        border-bottom-color: var(--v-primary-base) !important;
      }
    }
  }
  .--main {
    background-color: white;
    grid-area: main;
    overflow: auto;
    padding-bottom: 5rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-bottom: 0;
    }
  }
  .--action {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 2.5rem;
    right: 2.5rem;
    padding: 1.5rem 0;
    grid-area: action;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      background-color: transparent;
      position: relative;
      inset: unset;
    }
  }
}
</style>
